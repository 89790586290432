import { useEffect, useState } from 'react';
import './App.css';
import SeqropsGrafanaComponent from './components/grafana.component/grafana';
import { Navigate, Route, Routes } from 'react-router-dom';
import { encryptUtils } from './utils/encryption.utils';
import { ASSET_SUITE_LOGIN_URL, COOKIE_DETAILS, IFRAME_ID, KEYCLOAK_GRANT_TYPES, SESSION_KEY, SESSION_TYPE, THEME_TYPE } from './config/default.config';
import { ITokenDetails, IValidateTokenPayload } from './store/keycloak/asset-keycloak.interface';
import { setLogin, setTheme } from './store/seqr-ops/seqr-ops.slice';
import { useAppDispatch, useAppSelector } from './store';
import { validateToken } from './store/keycloak/asset-keycloak.action';
import { cookieUtils } from './utils/cookie.utils';
import { dark } from '@mui/material/styles/createPalette';


function App() {

  const dispatch = useAppDispatch();
  const [ready, setReady] = useState<boolean>(false);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [localThemeState, setLocalThemeState] = useState<string>("");
  
  useEffect(() => {
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
      console.log("decryptedCookieData ::: ", decryptedCookieData);
      if (decryptedCookieData?.themeType === "LIGHT") {
        dispatch(setTheme(false));
      } else if (decryptedCookieData?.themeType === "DARK") {
        dispatch(setTheme(true));
      }
      
      if (decryptedCookieData?.sessionId != null && decryptedCookieData?.sessionId !== undefined && decryptedCookieData?.sessionId !== "") {
        const validation = await validateToken(decryptedCookieData?.sessionId, setReady);
        if (!validation) {
          setReady(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName); 
          window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setReady(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };
    validateAuth();
  }, []);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);

    if(!ready){
      return null;
    }

  return (
    <div className="App" id={localThemeState}>
      <Routes>
        <Route path="/" element={<SeqropsGrafanaComponent/> }></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
  </div>
  );
}

export default App;
