import { useEffect, useState } from "react";
import {
  SEQROPS_OPERATIONAL_DASHBOARD_GRAFANA_LOGIN_URL,
  SEQROPS_MANAGEMENET_DASHBOARD_GRAFANA_LOGIN_URL,
  SESSION_KEY,
  KEYCLOAK_CLIENTS,
  COOKIE_DETAILS,
  SEQROPS_OPERATIONAL_DASHBOARD_IPAD_GRAFANA_LOGIN_URL,
  SEQROPS_MANAGEMENET_DASHBOARD_IPAD__GRAFANA_LOGIN_URL,
  SEQROPS_SERVER_ROOM_MONITORING_DASHBOARD_IPAD_GRAFANA_LOGIN_URL,
  SEQROPS_SERVER_ROOM_MONITORING_DASHBOARD_GRAFANA_LOGIN_URL,
  ASSET_REDIRECT_SCOPES,
} from "../../config/default.config";
import Navbar from "../navbar.component/navbar";
import { useAppSelector } from "../../store";
import {
  IRenderDashboard,
  IUserAuthorizations,
} from "../../store/keycloak/asset-keycloak.interface";
import { encryptUtils } from "../../utils/encryption.utils";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { cookieUtils } from "../../utils/cookie.utils";

function SeqropsGrafanaComponent() {
  const authorozedScopes = useAppSelector(
    (state) => state.assetKeycloak.userAuthorizations
  );
  const themeFromStore = useAppSelector((state) => state.seqrOps.theme);
  const authToken = useAppSelector((state) => state.assetKeycloak.auth_token);
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [theme, setTheme] = useState<string>("light");
  const [renderDashboard, setRenderDashboard] = useState<IRenderDashboard>({
    operational_dashboard: false,
    management_dashboard: false,
    monitoring_dashboard: false,
  });
  const [operationalDashboardURL, setOperationalDashboardURL] =
    useState<string>("");
  const [managementDashboardURL, setManagementDashboardURL] =
    useState<string>("");
  const [
    serverRoomMonitoringDashboardURL,
    setServerRoomMonitoringDashboardURL,
  ] = useState<string>("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let isIpad: boolean = false;
    if (`${windowWidth}` <= "1080") {
      isIpad = true;
    }

    const operationalDashboardUrl = isIpad
      ? SEQROPS_OPERATIONAL_DASHBOARD_IPAD_GRAFANA_LOGIN_URL
      : SEQROPS_OPERATIONAL_DASHBOARD_GRAFANA_LOGIN_URL;

    const managementDashboardUrl = isIpad
      ? SEQROPS_MANAGEMENET_DASHBOARD_IPAD__GRAFANA_LOGIN_URL
      : SEQROPS_MANAGEMENET_DASHBOARD_GRAFANA_LOGIN_URL;

    const serverRoomMonitoringDashboardURL = isIpad
      ? SEQROPS_SERVER_ROOM_MONITORING_DASHBOARD_IPAD_GRAFANA_LOGIN_URL
      : SEQROPS_SERVER_ROOM_MONITORING_DASHBOARD_GRAFANA_LOGIN_URL;

    setOperationalDashboardURL(operationalDashboardUrl);
    setManagementDashboardURL(managementDashboardUrl);
    setServerRoomMonitoringDashboardURL(serverRoomMonitoringDashboardURL);

    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      const urlParams = new URLSearchParams(window.location.search);
      const dashboardType = urlParams.get("dashboardType");
      if (tokenData !== null) {
        if (dashboardType !== null) {
          let renderDashboardData: IRenderDashboard = {
            operational_dashboard: false,
            management_dashboard: false,
            monitoring_dashboard: false,
          };
          if (dashboardType === ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD) {
            renderDashboardData.management_dashboard = true;
          } else if (
            dashboardType === ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD
          ) {
            renderDashboardData.operational_dashboard = true;
          } else if (
            dashboardType === ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD
          ) {
            renderDashboardData.monitoring_dashboard = true;
          }
          setRenderDashboard(renderDashboardData);
        }
        setLoggedUser(tokenData?.token?.username);
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setToken(tokenData?.token?.auth_token);
      }
    } else {
      setToken("");
    }
  }, [authorozedScopes]);

  useEffect(() => {
    if (token !== "" && token !== authToken && authToken !== "") {
      setToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (themeFromStore) {
      setTheme("dark");
    } else if (!themeFromStore) {
      setTheme("light");
    }
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let encryptedCookieData = cookieUtils.getCookie(cookieName);
    let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
    console.log("decryptedCookieData ::: ", decryptedCookieData);
  }, [themeFromStore]);

  const renderOperationalDashboard = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          operationalDashboardURL + "&auth_token=" + token + "&theme=" + theme
        }
        title="seqrops-operational-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };

  const renderManagementDashboard = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          managementDashboardURL + "&auth_token=" + token + "&theme=" + theme
        }
        title="seqrops-management-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };

  const renderServerRoomMonitoringDashboard = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          serverRoomMonitoringDashboardURL +
          "&auth_token=" +
          token +
          "&theme=" +
          theme
        }
        title="seqrops-service-room-monitoring-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };
  console.log(
    "HEIII  ::: ",
    userScopesFromLocalStorage !== undefined &&
      userScopesFromLocalStorage.monitoring_dashboard &&
      renderDashboard.monitoring_dashboard
  );
  return (
    <div>
      <div className="seqrops-navbar-section">
        <Navbar
          loggeduser={
            userScopesFromLocalStorage !== undefined ? loggedUser : "Admin"
          }
        />
      </div>
      {(userScopesFromLocalStorage !== undefined
        ? userScopesFromLocalStorage.operational_dashboard &&
          renderDashboard.operational_dashboard
        : authorozedScopes.operational_dashboard) &&
        renderOperationalDashboard()}
      {(userScopesFromLocalStorage !== undefined
        ? userScopesFromLocalStorage.management_dashboard &&
          renderDashboard.management_dashboard
        : authorozedScopes.management_dashboard) && renderManagementDashboard()}
      {(userScopesFromLocalStorage !== undefined
        ? userScopesFromLocalStorage.monitoring_dashboard &&
          renderDashboard.monitoring_dashboard
        : authorozedScopes.monitoring_dashboard) &&
        renderServerRoomMonitoringDashboard()}
    </div>
  );
}

export default SeqropsGrafanaComponent;
